import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import Modi from '../src/components/Modi/Modi';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import GeneralTab from '../src/components/Tabs/HowToArrive/howtoarrive-tab';
import buildFunnelUrl from '../src/helpers/buildFunnelUrl';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query howToArrive($locale: String!) {
    allArriveSeoData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allArriveTitleHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
        }
      }
    }
    allArriveImageHeader(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allArriveBreadCrumbBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allArriveTabsData(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          car
          train
          plane
          bus
          boat
          madrid
          image
          image_alt
        }
      }
    }
    allArriveRichData {
      edges {
        node {
          rich_markup {
            color_css_class
            og_description
            og_title
            twitter_description
            twitter_title
            created_at
            updated_at
            rich_markup_type
          }
        }
      }
    }
  }
`;

class ComoLlegar extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allArriveTitleHeader.edges[0].node.title,
      image: {
        url: this.props.data.allArriveImageHeader.edges[0].node.localImage.childImageSharp.fluid,
        alt: this.props.data.allArriveImageHeader.edges[0].node.alt,
      },
    };

    const modiData = {
      Text: this.props.data.allArriveTitleHeader.edges[0].node.description,
      classContainer: 'no-image',
      button1: {
        cta: 'https://www.google.com/maps/dir/PortAventura%C2%AE+Resort,+Avinguda+de+l%27Alcalde+Pere+Molas,+km+2,+43840+Vila-seca,+Tarragona,+Spain/@41.0878239,1.1229152,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x12a159e61d964f11:0x545aab951bd2a8d0!2m2!1d1.157248!2d41.087829?hl=es',
        size: 'small',
        color: 'white',
        ctaText: tt('VER MAPA', this.props.pageContext.locale),
      },
      button2: {
        cta: tt('/entradas', this.props.pageContext.locale),
        size: 'small',
        color: 'white',
        ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
      },
      button3: {
        cta: buildFunnelUrl(this.props.pageContext.locale, 'hotels'),
        size: 'small',
        color: 'orange',
        ctaText: tt('RESERVAR AHORA', this.props.pageContext.locale),
      },
    };
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
      >
        <SEO
          title={this.props.data.allArriveSeoData.edges[0].node._0.title}
          description={this.props.data.allArriveSeoData.edges[0].node._1.description}
          lang={this.props.pageContext.locale}
          pathname={this.props.pageContext.url}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="hta-container">
              <Modi data={modiData} />
              <BlueArrow />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allArriveBreadCrumbBlock.edges[0].node.name}
              />
              <Hod
                data={this.props.data.allArriveRichData.edges[0].node.rich_markup}
                url={this.props.pageContext.url}
                img={
                  this.props.data.allArriveImageHeader.edges[0].node.localImage.childImageSharp
                    .fluid
                }
              />
              <GeneralTab
                locale={this.props.pageContext.locale}
                data={this.props.data.allArriveTabsData.edges[0].node}
              />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

ComoLlegar.propTypes = {
  howToArriveData: PropTypes.object.isRequired,
};

export default ComoLlegar;
