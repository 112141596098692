import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { MediaMatcher, MediaServerRender } from 'react-media-match';
import { injectIntl } from 'react-intl';
import Icon from '../../../helpers/icon';
import arriveIcon from './arrive-icons';
import { Odin } from '../../Odin/Odin';
import tt from '../../../helpers/translation';

import Car from './car';

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography
      component="div"
      style={{
        padding: '10px',
        background: 'white',
        border: 'none',
        marginTop: '50px',
        fontFamily: 'Lato',
      }}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: 'none',
  },
  default_tabStyle: {
    color: '#5E5E5E',
    backgroundColor: 'white',
    opacity: 1.2,
    marginBottom: '10px',
    height: '50px',
    width: '160px',
    marginRight: '3px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '21px',
    textAlign: 'center',
    fontFamily: 'Lato',
  },

  active_tabStyle: {
    height: '60px',
    width: '160px',
    borderRadius: '0 0 10px 10px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    color: '#1396D1',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    fontFamily: 'Lato',
  },
  last_default_tabStyle: {
    color: '#5E5E5E',
    backgroundColor: 'white',
    opacity: 1.2,
    marginBottom: '10px',
    height: '50px',
    width: '190px',
    marginRight: '0px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '21px',
    textAlign: 'center',
    fontFamily: 'Lato',
  },

  last_active_tabStyle: {
    height: '60px',
    width: '190px',
    borderRadius: '0 0 10px 10px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    color: '#1396D1',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    fontFamily: 'Lato',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  labelContainer: {
    padding: '0px',
    width: '55%',
    fontFamily: 'Lato',
  },
  last_labelContainer: {
    padding: '0px',
    width: '75%',
    fontFamily: 'Lato',
  },
  Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    marginTop: '15px',
  },
  labelIcon: {
    minHeight: '0px',
    paddingTop: '0px',
  },
});

class GeneralTab extends React.Component {
  state = {
    value: 0,
    type: 'EN COCHE',
    openmenu1: false,
    openmenu2: false,
    openmenu3: false,
  };

  handleClick = key => e => {
    const obj = {};
    this.state[key] = !this.state[key];
    this.setState(obj);
  };

  handleChangeTab = (event, value) => {
    this.setState({ value });
    this.handleTypeChange(value);
  };

  handleChange = value => {
    this.setState({ value });
    this.handleTypeChange(value);
  };

  handleTypeChange = value => {
    switch (value) {
      case 0:
        this.setState({ type: tt('EN COCHE', this.props.intl.locale) });
        break;

      case 1:
        this.setState({ type: tt('TREN', this.props.intl.locale) });
        break;

      case 2:
        this.setState({ type: tt('AVIÓN', this.props.intl.locale) });
        break;

      case 3:
        this.setState({ type: tt('AUTOBÚS', this.props.intl.locale) });
        break;

      case 4:
        this.setState({ type: tt('EN CRUCERO', this.props.intl.locale) });
        break;

      case 5:
        this.setState({ type: tt('DESDE MADRID', this.props.intl.locale) });
        break;

      default:
        this.setState({ type: tt('EN COCHE', this.props.intl.locale) });
        break;
    }
  };

  render() {
    const {
      state: { openmenu1 },
      props: { data, classes },
    } = this;

    const { value } = this.state;

    const odinData = {
      image: data.image,
      alt: data.image_alt,
      title: tt('¡NO TE PIERDAS! UTILIZA NUESTRAS COORDENADAS GPS', this.props.intl.locale),
      description: tt(
        'Las coordenadas GPS para llegar al resort son: Latitud: 41º05"15.46"N Longitud:01º09"26.02"E / Decimal: 41.087628; 1.157228',
        this.props.intl.locale
      ),
      description2: tt('Consulta aquí la ruta indicando tu origen.', this.props.intl.locale),
      rssImg: true,
      buttons: {
        cta1:
          'https://www.google.com/maps/dir/PortAventura+Hotel+PortAventura,+Avinguda+del+Batlle+Pere+Molas,+s%2Fn,+43840,+Tarragona/@41.0856513,1.152512,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x12a15997f615c0f9:0xde7562454a8b708e!2m2!1d1.1544399!2d41.0843477!1m5!1m1!1s0x12a159e61d964f11:0x545aab951bd2a8d0!2m2!1d1.1572475!2d41.0878286?hl',
        ctaText1: tt('VER MAPA', this.props.intl.locale),
        size: 'alone',
        color1: 'orange',
      },
    };

    const isMobileTrue = (
      <div className="tab-menu bggrey">
        <ListItem button className="firstlevelpasesmenu" onClick={this.handleClick('openmenu1')}>
          <ListItemText className="firstlevelpasesmenu-text" inset primary={`${this.state.type}`} />
          {openmenu1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openmenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className="secondpaseslevelmenu"
              value={0}
              onClick={() => this.handleChange(0)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('EN COCHE', this.props.intl.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value={1}
              onClick={() => this.handleChange(1)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('TREN', this.props.intl.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value={2}
              onClick={() => this.handleChange(2)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('AVIÓN', this.props.intl.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value={3}
              onClick={() => this.handleChange(3)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('AUTOBÚS', this.props.intl.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value={4}
              onClick={() => this.handleChange(4)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('EN CRUCERO', this.props.intl.locale)}
              />
            </ListItem>

            <ListItem
              button
              className="secondpaseslevelmenu"
              value={5}
              onClick={() => this.handleChange(5)}
            >
              <ListItemText
                className="secondpaseslevelmenu-text"
                inset
                primary={tt('DESDE MADRID', this.props.intl.locale)}
              />
            </ListItem>
          </List>
        </Collapse>
      </div>
    );

    const isBrowserTrue = (
      <Tabs
        value={value}
        onChange={this.handleChangeTab}
        centered
        variant="fullWidth"
        className="tab-head"
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={tt('EN COCHE', this.props.intl.locale)}
          icon={
            value === 0 ? (
              <Icon
                icon="by_car"
                color="#1396D1"
                width="25"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="by_car"
                  color="#5E5E5E"
                  width="25"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={
            value === 0
              ? classes.active_tabStyle + " active-tab" : classes.default_tabStyle + " default-tab"} //eslint-disable-line
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('TREN', this.props.intl.locale)}
          icon={
            value === 1 ? (
              <Icon
                icon="by_train"
                color="#1396D1"
                width="20"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="by_train"
                  color="#5E5E5E"
                  width="20"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={value === 1 ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('AVIÓN', this.props.intl.locale)}
          icon={
            value === 2 ? (
              <Icon
                icon="by_plane"
                color="#1396D1"
                width="110"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="by_plane"
                  color="#5E5E5E"
                  width="110"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={value === 2 ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('AUTOBÚS', this.props.intl.locale)}
          icon={
            value === 3 ? (
              <Icon
                icon="By_bus"
                color="#1396D1"
                width="20"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="By_bus"
                  color="#5E5E5E"
                  width="20"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={value === 3 ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('EN CRUCERO', this.props.intl.locale)}
          icon={
            value === 4 ? (
              <Icon
                icon="uniE95F"
                color="#1396D1"
                width="20"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="uniE95F"
                  color="#5E5E5E"
                  width="20"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={value === 4 ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer: classes.labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
        <Tab
          label={tt('DESDE MADRID', this.props.intl.locale)}
          icon={
            value === 5 ? (
              <Icon
                icon="from_madrid"
                color="#1396D1"
                width="60"
                height="20"
                class="is-centered"
                iconSet={arriveIcon}
              />
            ) : (
                <Icon
                  icon="from_madrid"
                  color="#5E5E5E"
                  width="60"
                  height="20"
                  class="is-centered"
                  iconSet={arriveIcon}
                />
              )
          }
          className={value === 5 ? classes.last_active_tabStyle : classes.last_default_tabStyle}
          classes={{
            labelContainer: classes.last_labelContainer,
            wrapper: classes.Wrapper,
            labelIcon: classes.labelIcon,
          }}
        />
      </Tabs>
    );

    return (
      <>
        <div className="tab-container">
          <MediaServerRender predicted="desktop" hydrated>
            <MediaMatcher mobile={isMobileTrue} desktop={isBrowserTrue} />
          </MediaServerRender>

          {value === 0 && (
            <TabContainer>
              <Car content={data.car} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <Car content={data.train} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
          {value === 2 && (
            <TabContainer>
              <Car content={data.plane} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
          {value === 3 && (
            <TabContainer>
              <Car content={data.bus} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
          {value === 4 && (
            <TabContainer>
              <Car content={data.boat} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
          {value === 5 && (
            <TabContainer>
              <Car content={data.madrid} title={tt('CÓMO LLEGAR', this.props.intl.locale)} />
              <Odin data={odinData} locale={this.props.intl.locale}/>
            </TabContainer>
          )}
        </div>
      </>
    );
  }
}

export default injectIntl(withStyles(styles)(GeneralTab));
