import './Odin.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Buttons from '../Buttons/Buttons';
import Pawlink from '../PawLink/PawLink';

export const Odin = (props) => {
  const {
    locale,
    data: {
      image,
      title,
      description,
      description2,
      address,
      rssImg,
      titleType,
      alt,
      buttons: { cta1, cta2, size, color1, color2, ctaText1, ctaText2, blank },
    },
  } = props;
  let areTwoButtons;
  if (cta2) {
    areTwoButtons = (
      <Buttons
        link={`${cta2}?_ga=2.76409685.1700126798.1640597527-1648847946.1639043608`}
        size={size}
        color={color2}
        text={ctaText2}
        blank={blank}
      />
    );
  }

  let isDescription;

  if (description) {
    isDescription = (
      <div dangerouslySetInnerHTML={{ __html: description }} className="odin-description-text" />
    );
  }

  let isDescription2;

  if (description2) {
    isDescription2 = <span className="odin-description2-text">{description2}</span>;
  }

  let isRss;
  if (rssImg) {
    isRss = (
      <div className="odin-images-cont">
        <div className="odin-images">
          <Pawlink
            type="unknown"
            destination="https://wa.me/whatsappphonenumber/?text=urlencodedtext"
            rel=" noopener "
          >
            <div className="odin-img-whatsapp" />
          </Pawlink>
          <Pawlink
            type="unknown"
            destination="https://twitter.com/Portaventura_ES"
            rel=" noopener "
          >
            <div className="odin-img-twitter" />
          </Pawlink>
          <Pawlink
            type="unknown"
            destination="https://www.facebook.com/portaventuraoficial"
            rel=" noopener "
          >
            <div className="odin-img-facebook" />
          </Pawlink>
          <Pawlink
            type="unknown"
            destination="https://www.youtube.com/user/PortAventuraTV"
            rel=" noopener "
          >
            <div className="odin-img-youtube" />
          </Pawlink>
          <Pawlink
            type="unknown"
            destination="https://www.instagram.com/portaventuraworld"
            rel=" noopener "
          >
            <div className="odin-img-instagram" />
          </Pawlink>
        </div>
      </div>
    );
  }

  let isTitle;
  if (titleType === 'h3' && title) {
    isTitle = <h3 className="odin-title-text">{title}</h3>;
  } else if (titleType === 'h2' && title) {
    isTitle = <h2 className="odin-title-text">{title}</h2>;
  } else if (title) {
    isTitle = <div className="odin-title-text">{title}</div>;
  }

  return (
    <div className="odin-container">
      <div className="img-container">
        <img className="img-fullwidth" src={image} alt={alt} />
      </div>
      <div className="odin-container-box">
        <div className="standard-box">
          <div className="container-text">
            {isTitle}
            {isDescription}
            {isDescription2}
            <div dangerouslySetInnerHTML={{ __html: address }} />
          </div>
          <div className="container-buttons">
            {cta1 ? (
              <Buttons link={cta1} size={size} color={color1} text={ctaText1} blank={blank} />
            ) : null}
            {areTwoButtons}
          </div>
          {isRss}
        </div>
      </div>
    </div>
  );
};

Odin.propTypes = {
  data: PropTypes.object,
};

Odin.defaultProps = {
  data: {
    image:
      'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/008/596/original/servicios-hotel-colorado-creek-600x600.jpg',
    title: 'HOTEL COLORADO CREEK. NUEVO - HOTEL 4 ESTRELLAS SUPERIOR DE PORTAVENTURA WORLD',
    description:
      'Un hotel de 4 estrellas sperior lleno de comodidades. Ya puedes reservar en el nuevo Hotel Colorado Creek en PortAventura World. Ubicado en la zona Far West, cuenta con todos los lujos. ¡Descúbrelo!',
    buttons: {
      cta1: '/entradas',
      cta2: '/entradas',
      ctaText1: 'asd',
      ctaText2: 'RESERVAR AHORA',
      size: 'alone',
      color1: 'white',
      color2: 'orange',
    },
  },
};
