import React from 'react';
import PropTypes from 'prop-types';

import './car.scss';

const Car = ({ content, title }) => (
  <div className="car-container">
    <div className="text-container">
      <h2 className="title">
        {title}
      </h2>
      <div className="description" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
);

Car.propTypes = {
  carData: PropTypes.object.isRequired,
};

export default Car;
